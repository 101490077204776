import React from "react"
import Layout from "../components/Layout"

const Internal = ({ location }) => {
  const pageTitle = "Internal View"
  const pageSlug = "internal-view"

  return (
    <Layout location={location} pageSlug={pageSlug} pageTitle={pageTitle}>
      <div
        className="container"
        style={{ paddingTop: "10rem", minHeight: "85vh" }}
      >
        <div className="head__wrapper">
          <h2 className="head font-headline">{pageTitle}</h2>
        </div>
      </div>
    </Layout>
  )
}

export default Internal
